
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.root {
  @include desktop {
    display: none;
  }

  @include mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rbg(var(--jet));
    text-align: start;
    width: 100%;
    padding: 16px 24px;
    background-color: rgb(var(--isabelline));
    font-size: pxToRem(16);
    line-height: pxToRem(23);

    .rightArrow {
      font-size: pxToRem(24);
      margin-left: 8px;
    }

    .text {
      flex-grow: 1;

      @include trim();
    }
  }
}
