
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.button {
  position: fixed;
  bottom: 150px;
  left: -55px;
  background-color: #132231;
  border: 2px solid white;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
  z-index: calc(var(--popups-index) + 1);
  padding: 7px 7px 7px 20px;
  transition: left 0.3s;
  color: white;

  &:hover,
  &:focus {
    background-color: #17385b;
    left: -15px;
  }

  .handicap {
    display: inline-block;
    vertical-align: middle;
    font-size: pxToRem(20);
  }

  .info {
    /* account for icon spacing */
    margin-top: -6px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;

    svg {
      font-size: pxToRem(16);
    }

    p {
      font-size: pxToRem(8);
      word-break: keep-all;
      width: 30px;
    }
  }

  &.hide {
    display: none;
  }
}
