
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.footer {
  background-color: rgb(var(--jet));
  color: rgb(var(--pastelGrey));
  grid-template-areas: 'footer';

  @include desktop {
    padding: 50px 0 10px;
  }
  @include mobile {
    padding: 25px;
  }
}

.container {
  max-width: 950px;
  margin-inline: auto;
}

.aboutText {
  color: rgb(var(--spanishGrey));
  text-align: center;
  margin-bottom: 40px;

  @include desktop {
    max-width: 850px;
    margin: 0 auto 36px;
  }
  @include mobile {
    font-size: var(--font-size-xs);
    line-height: 18px;
  }
}

.terms {
  font-size: var(--font-size-xxs);
  color: rgb(var(--spanishGrey));
  text-align: center;

  @include desktop {
    margin: 20px 0;
  }
  @include mobile {
    margin: 30px 0;
  }
}

.divider {
  background: #5a5a5a;
  border: none;
  height: 1px;

  @include desktop {
    width: 420px;
    margin: auto;
  }
}

.environments {
  display: block;
  text-align: center;
  color: rgb(var(--white));
  margin-top: 10px;
}
