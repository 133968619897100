@import './variants-variables.scss';

@mixin primaryDisable {
  &:disabled {
    background-color: rgb(var(--isabelline));
    color: rgb(var(--pastelGrey));
    cursor: not-allowed;
  }
}

@mixin secondaryDisable {
  &:disabled {
    pointer-events: none;
    border: 1px solid rgb(var(--gainsboro));
    color: rgb(var(--pastelGrey));
  }
}

@mixin transparentDisable {
  &:disabled {
    cursor: not-allowed;
    color: rgb(var(--pastelGrey));
  }
}

@mixin primaryStyle {
  background-color: rgb(var(--safetyOrange));
  color: rgb(var(--white));
  border: none;
  @include desktop {
    &:hover {
      background-color: rgb(var(--darkOrange));
    }
  }
  &:active {
    background-color: rgb(var(--vividOrange));
  }
  @include primaryDisable();
}

@mixin blueStyle {
  background-color: rgb(var(--royalBlue));
  color: rgb(var(--white));
  border: none;
  @include desktop {
    &:hover {
      background-color: rgb(var(--blueShade));
    }
  }
  &:active {
    background-color: rgb(var(--blueLight));
  }
  @include primaryDisable();
}

@mixin secondaryStyle {
  background-color: rgb(var(--white));
  color: rgb(var(--safetyOrange));
  border: 1px solid rgb(var(--safetyOrange));
  @include desktop {
    &:hover {
      background-color: rgb(var(--bianca));
    }
  }
  @include secondaryDisable();
}

@mixin tertiaryStyle {
  background-color: rgb(var(--white));
  color: rgb(var(--jet));
  border: 1px solid rgb(var(--pastelGrey));
  font-weight: var(--font-weight-normal);
  @include desktop {
    &:hover {
      border-color: rgb(var(--spanishGrey));
    }
  }
  &:active {
    background-color: rgb(var(--snow));
  }
  @include secondaryDisable();
}

@mixin greenStyle {
  background-color: rgb(var(--caribbeanGreen));
  color: rgb(var(--white));
  border: none;
  @include desktop {
    &:hover {
      background-color: rgb(var(--greenCyan));
    }
  }
  &:active {
    background-color: rgb(var(--emerald));
  }
  @include primaryDisable();
}

@mixin greenOutlinedStyle {
  background-color: white;
  color: rgb(var(--caribbeanGreen));
  border: 1px solid rgb(var(--caribbeanGreen));
  @include primaryDisable();
}

@mixin blueOutlinedStyle {
  background-color: white;
  color: rgb(var(--azure));
  border: 1px solid rgb(var(--azure));
  @include desktop {
    &:hover {
      background-color: rgb(var(--aliceBlue));
    }
  }
  @include primaryDisable();
}

@mixin transparentStyle {
  color: rgb(var(--jet));

  @include transparentDisable();
}

@mixin transparentPrimaryStyle {
  color: rgb(var(--safetyOrange));

  @include transparentDisable();
}

@mixin fetchVariantStyle($value) {
  @if $value == $primary {
    @include primaryStyle();
  } @else if $value == $secondary {
    @include secondaryStyle();
  } @else if $value == $tertiary {
    @include tertiaryStyle();
  } @else if $value == $green {
    @include greenStyle();
  } @else if $value == $transparent {
    @include transparentStyle();
  } @else if $value == $transparentPrimary {
    @include transparentPrimaryStyle();
  } @else if $value == $greenOutlined {
    @include greenOutlinedStyle();
  } @else if $value == $blueOutlined {
    @include blueOutlinedStyle();
  } @else if $value == $blue {
    @include blueStyle();
  }
}
