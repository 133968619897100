
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

$gap: 20px;

.content {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  padding: 25px 32px;
  color: rgb(var(--jet));

  &:not(.visible) {
    display: none;
  }

  .headerWrapper {
    width: 100%;

    .header {
      display: block;
      font-size: pxToRem(20);
      line-break: pxToRem(28);
      margin-bottom: 20px;

      .icon {
        line-height: 0;
        margin-top: 2px;
        font-size: pxToRem(16);
      }

      * {
        display: inline-block;
        vertical-align: middle;
      }

      strong {
        font-weight: 500;
      }
    }
  }

  .subCategoryItem {
    width: calc((100% / 3) - ($gap * 2 / 3));
  }
}
