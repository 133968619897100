
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.listItem {
  grid-column: var(--desktop-column-placement);

  &:not(:last-child) {
    @include mobile {
      border-bottom: 1px solid rgb(var(--isabelline));
    }
  }

  .link {
    display: flex;
    align-items: center;

    &:hover {
      color: rgb(var(--safetyOrange));
    }

    @include desktop {
      min-width: 150px;
    }
  }

  .iconWrapper {
    line-height: 1em;

    &::after {
      content: ' ';
      white-space: pre;
    }

    @include mobile {
      display: none;
    }
  }
}
