:root {
  // SHADOWS
  --elevation-small: 0 2px 6px rgba(0, 0, 0, 0.06);
  --elevation-medium: 0 3px 9px rgba(0, 0, 0, 0.09);
  --elevation-large: 0 4px 12px rgba(0, 0, 0, 0.12);
  --elevation-xlarge: 0 5px 15px rgba(0, 0, 0, 0.15);
  --elevation-top: 0 -4px 12px rgba(0, 0, 0, 0.08);
  --elevation-bottom: 0 4px 12px rgba(0, 0, 0, 0.08);
  --elevation-left: -4px 0 12px rgba(0, 0, 0, 0.08);
  --elevation-right: 4px 0 12px rgba(0, 0, 0, 0.08);

  // Shadows
  --shadow-level-1: 0 3px 10px 0 rgba(var(--black), 0.05);
  --shadow-level-2: 0 3px 10px 0 rgba(var(--black), 0.1);
  --shadow-level-3: 0 3px 10px 0 rgba(var(--black), 0.15);
  --shadow-level-4: 0 3px 10px 0 rgba(var(--black), 0.2);
}
