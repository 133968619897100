:root {
  --border-radius-full: 9999px;
  --border-radius-xlarge: 30px;
  --border-radius-large: 8px;
  --border-radius-medium: 7px;
  --border-radius-normal: 4px;
  --border-radius-small: 2px;
  --border-radius-none: 0;
  // DEFAULT
  --border-radius: 4px;
}
