$duration: 0.3s;

@keyframes popModal {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideRight {
  0% {
    right: -315px;
  }

  100% {
    right: 0;
  }
}

@keyframes slideLeft {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@keyframes bottomModal {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@mixin modal($type) {
  @if $type == right {
    animation: slideRight $duration ease;
  } @else if $type == left {
    animation: slideLeft $duration ease;
  } @else if $type == bottom {
    animation: bottomModal $duration ease;
  } @else {
    animation: popModal $duration ease;
  }
}

@mixin hideScrollbar() {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
