@import '../base/variants-variables.scss';

@mixin smallIcon() {
  .icon {
    line-height: 0;
    svg {
      @include iconMedium();
    }
  }
  &.left {
    padding-left: 12px;

    .icon {
      margin-right: 4px;
    }
  }
  &.right {
    padding-right: 12px;

    .icon {
      margin-left: 4px;
    }
  }
}

@mixin mediumIcon() {
  .icon {
    line-height: 0;
    svg {
      @include iconCustom(1.375rem);
    }
  }
  &.left {
    padding-left: 14px;

    .icon {
      margin-right: 4px;
    }
  }
  &.right {
    padding-right: 14px;

    .icon {
      margin-left: 4px;
    }
  }
}

@mixin largeIcon() {
  .icon {
    line-height: 0;
    svg {
      @include iconLarge();
    }
  }
  &.left {
    padding-left: 16px;

    .icon {
      margin-right: 4px;
    }
  }
  &.right {
    padding-right: 16px;

    .icon {
      margin-left: 4px;
    }
  }
}

@mixin fetchWithIcon($value) {
  @if $value == $small {
    @include smallIcon();
  } @else if $value == $medium {
    @include mediumIcon();
  } @else if $value == $large {
    @include largeIcon();
  }
}
