
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.tag {
  padding: 0 4px;
  min-width: 28px;
  line-height: pxToRem(16);
  margin-right: 8px;
  border-radius: 4px;
  background-color: rgb(var(--caribbeanGreen));
  color: rgb(var(--white));
  font-size: pxToRem(10);
  font-weight: var(--font-weight-medium);
  text-align: center;
  vertical-align: middle;
}
