@mixin trim {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

@mixin trim-table {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin remove-trim {
  white-space: normal;
  overflow: visible;
}

@mixin trimOnLines($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
