
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '../../mixins/modal.mixins.scss';
$defaultPadding: 12px;

.box {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--popups-index);
  overflow-y: scroll;
  @include hideScrollbar();
  .window {
    z-index: var(--popups-index);

    &.frame {
      position: relative;
      background-color: rgb(var(--white));
      box-shadow: var(--elevation-xlarge);
      padding: $defaultPadding;
      border-radius: 10px;
      overflow-x: hidden;
      outline: none;
    }
  }

  @include desktop {
    align-content: center;
    justify-content: center;

    .window {
      margin: auto;
      @include modal(center);
    }
  }

  @include mobile {
    &.regular {
      align-content: center;
      justify-content: center;

      .window {
        margin: auto;
        @include modal(center);
      }
    }
    &.fullScreen {
      align-content: center;
      justify-content: center;

      .window {
        width: 100%;
        height: 100%;
        margin: auto;
        @include modal(center);
      }
    }
    &.leftSide {
      justify-content: flex-end;

      .window {
        width: 100%;
        height: 100%;
        @include modal(left);
      }
    }
    &.rightSide {
      justify-content: flex-end;

      .window {
        width: 100%;
        height: 100%;
        @include modal(right);
      }
    }
    &.drawer {
      justify-content: center;
      align-items: flex-end;

      .window {
        width: 100%;
        max-height: 80%;
        @include modal(bottom);
      }
    }
    &.fullScreenDrawer {
      justify-content: center;
      align-items: flex-end;

      .window {
        width: 100%;
        height: 100%;
        @include modal(bottom);
      }
    }
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: hsla(0, 0%, 5%, 0.4);
    z-index: calc(var(--popups-index) - 1);
  }
}
