
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.navButton {
  position: relative;
  display: flex;
  padding: 0 20px;
  height: 100%;
  align-items: center;

  @include mobile {
    margin: 12px;
    padding: 0;

    &:not(.hasLinkBehaviourInMobile) {
      pointer-events: none;
    }

    .iconWrapper {
      line-height: 1em;
      // background-color: gray;

      &::after {
        content: ' ';
        white-space: pre;
      }
    }
  }
}

.arrowIcon {
  @include mobile {
    position: absolute;
    left: 0;
  }

  @include desktop {
    display: none !important;
  }
}

.mobileOnly {
  @include desktop {
    display: none !important;
  }
}

.desktopOnly {
  @include mobile {
    display: none !important;
  }
}
