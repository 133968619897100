
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.popover {
  position: absolute;
  background-color: rgb(var(--white));

  z-index: var(--floaters-index);
  font-size: var(--font-size-s);

  @include mobile {
    position: fixed;
    width: var(--menu-width);
    top: 0;
    bottom: 0;
    right: calc(var(--menu-width) * -1);
    transition: right 0.3s;
    overflow: auto;

    &.open {
      right: 0;
    }
  }

  @include desktop {
    visibility: hidden;
    width: max-content;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);

    &.open {
      visibility: visible;
    }
  }
}
