@import '../../base/variants-variables.scss';

/** override lottie inline-css sizes **/
@mixin smallIcon() {
  width: 1.25rem;
  height: 1.25rem;
}

@mixin mediumIcon() {
  width: 1.375rem;
  height: 1.375rem;
}

@mixin largeIcon() {
  width: 1.5rem;
  height: 1.5rem;
}

@mixin fetchWithLoaderSize($value) {
  @if $value == $small {
    @include smallIcon();
  } @else if $value == $medium {
    @include mediumIcon();
  } @else if $value == $large {
    @include largeIcon();
  }
}
