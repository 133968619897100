
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.listItem:hover {
  background-color: rgb(var(--snow));
}

.stickyTop {
  position: sticky;
  top: 0;
}

.content {
  @include desktop {
    --desktop-max-rows: 6;
    min-width: 225px;
    display: grid;
    grid-template-rows: repeat(var(--desktop-max-rows), 25px);
    grid-gap: 10px;
    gap: 11px;
    padding: 25px;
    grid-auto-flow: column;
    line-height: pxToRem(25);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: pxToRem(45);
    padding: 8px 30px;
  }
}
