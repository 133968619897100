
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.nav {
  margin-bottom: 40px;
}

.categoriesList {
  display: flex;

  @include desktop {
    justify-content: space-between;
  }
  @include mobile {
    flex-direction: column;
  }
}
