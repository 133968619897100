@import '../../base/variants-variables.scss';

@mixin primaryIconStyle {
  svg {
    @include iconColor(rgb(var(--white)), rgb(var(--white)));
  }
}

@mixin blueIconStyle {
  svg {
    @include iconColor(rgb(var(--white)), rgb(var(--white)));
  }
}

@mixin secondaryIconStyle {
  svg {
    @include iconColor(rgb(var(--safetyOrange)), rgb(var(--safetyOrange)));
  }
}

@mixin tertiaryIconStyle {
  svg {
    @include iconColor(rgb(var(--jet)), rgb(var(--jet)));
  }
}

@mixin greenIconStyle {
  svg {
    @include iconColor(rgb(var(--white)), rgb(var(--white)));
  }
}

@mixin greenOutlinedIconStyle {
  svg {
    @include iconColor(rgb(var(--caribbeanGreen)), rgb(var(--caribbeanGreen)));
  }
}

@mixin greenOutlinedIconStyle {
  svg {
    @include iconColor(rgb(var(--azure)), rgb(var(--azure)));
  }
}

@mixin transparentIconStyle {
  svg {
    @include iconColor(rgb(var(--jet)), rgb(var(--jet)));
  }
}
@mixin transparentPrimaryIconStyle {
  svg {
    @include iconColor(rgb(var(--safetyOrange)), rgb(var(--safetyOrange)));
  }
}

@mixin fetchLoaderVariantStyle($value) {
  @if $value == $primary {
    @include primaryIconStyle();
  } @else if $value == $secondary {
    @include secondaryIconStyle();
  } @else if $value == $tertiary {
    @include tertiaryIconStyle();
  } @else if $value == $green {
    @include greenIconStyle();
  } @else if $value == $transparent {
    @include transparentIconStyle();
  } @else if $value == $transparentPrimary {
    @include transparentPrimaryIconStyle();
  } @else if $value == $greenOutlined {
    @include greenOutlinedIconStyle();
  } @else if $value == $blue {
    @include blueIconStyle();
  }
}
