/**
    --- USAGE ---
    -- in component:
    import { MyIcon } from '@y2/mango/icons/..';

    <MyIcon className={styles.icon} />

    -- in scss
    .icon {
        @include iconSmall();
        // --- with color
        @include iconMedium(rgb(var(--safetyOrange)));
        // --- with custom size
        @include iconCustom(1.625rem);
        // --- with custom size & color
        @include iconCustom(1.625rem, rgb(var(--my-color)));

        // --- only with color
        @include iconColor(rgb(var(--my-color)));

        // --- for special cases if icons has specific inline-stroke(outline), you can add an extra prop
        @include iconLarge(rgb(var(--my-color)), true);
    }
*/

@mixin iconCustom($size, $color: '', $includeStroke: false) {
  width: $size;
  height: $size;
  @if (#{$color} != '') {
    @include iconColor($color, $includeStroke);
  }
}

@mixin iconColor($color, $includeStroke: false) {
  fill: $color;
  * {
    // override inline-style color that sometime comes with <path> or other svg patterns
    fill: $color !important;
  }

  @if $includeStroke {
    stroke: $color;

    * {
      // override inline-style color that sometime comes with <path> or other svg patterns
      stroke: $color !important;
    }
  }
}

// 32px
@mixin iconXXL($color: '', $includeStroke: false) {
  @include iconCustom(2rem, $color, $includeStroke);
}
// 28px
@mixin iconXL($color: '', $includeStroke: false) {
  @include iconCustom(1.75rem, $color, $includeStroke);
}
// 24px ~ Default
@mixin iconLarge($color: '', $includeStroke: false) {
  @include iconCustom(1.5rem, $color, $includeStroke);
}
// 20px
@mixin iconMedium($color: '', $includeStroke: false) {
  @include iconCustom(1.25rem, $color, $includeStroke);
}
// 16px
@mixin iconSmall($color: '', $includeStroke: false) {
  @include iconCustom(1rem, $color, $includeStroke);
}
// 12px
@mixin iconXS($color: '', $includeStroke: false) {
  @include iconCustom(0.75rem, $color, $includeStroke);
}
// 8px
@mixin iconXXS($color: '', $includeStroke: false) {
  @include iconCustom(0.5rem, $color, $includeStroke);
}
