
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.modal {
  color: rgb(var(--jet));
  text-align: center;
  width: 320px;

  .imageBox {
    margin-bottom: 10px;
  }
  .title {
    display: block;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);
    margin-bottom: 5px;
  }
  .content {
    display: block;
    font-size: var(--font-size-s);
    padding: 0 30px;
  }
  .divider {
    margin: 15px 0;
    height: 1px;
    width: 100%;
    background-color: rgb(var(--isabelline));
  }
  .buttonRows {
    margin-top: 40px;
    width: 100%;

    .button {
      width: 100%;
      margin-bottom: 20px;
    }
    .closeButton {
      color: rgb(var(--graniteGrey));
      width: 100%;
      line-height: 1.25rem;
      font-size: var(--font-size-xs);
      margin-bottom: 10px;
    }
  }
}
