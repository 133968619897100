
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.desktopOnly {
  @include mobile {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 995px;
  height: 610px;
  color: rgb(var(--jet));
  max-height: calc(100vh - 80px);
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

  .categories {
    max-height: 100%;
    min-width: 324px;
    overflow-y: auto;
    padding: 16px 12px;

    @include scrollbar(rgb(var(--snow)), rgb(var(--pastelGrey)));
  }

  .content {
    max-height: 100%;
    width: calc(100% - 300px);
    overflow-y: auto;

    @include scrollbar(rgb(var(--snow)), rgb(var(--pastelGrey)));
  }
}
