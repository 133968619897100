
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.mobileOnly {
  @include desktop {
    display: none;
  }
}

.list {
  padding: 5px 12px;
  width: 100%;
  color: rgb(var(--jet));
}
