
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import './variants-mixins.scss';
@import './variants-variables.scss';
@import './sizes-mixins.scss';

.button {
  border-radius: var(--border-radius-xlarge);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  font-style: normal;
  direction: rtl;

  &.flexed {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @each $value in $all-variants {
    &.default-#{$value} {
      @include fetchVariantStyle($value);
    }
    &.desktop-#{$value} {
      @include desktop {
        @include fetchVariantStyle($value);
      }
    }
    &.mobile-#{$value} {
      @include mobile {
        @include fetchVariantStyle($value);
      }
    }
  }

  @each $size in $all-sizes {
    &.default-size-#{$size} {
      @include fetchSizeStyle($size);
    }
    &.desktop-size-#{$size} {
      @include desktop {
        @include fetchSizeStyle($size);
      }
    }
    &.mobile-size-#{$size} {
      @include mobile {
        @include fetchSizeStyle($size);
      }
    }
  }
}
