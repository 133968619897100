
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.y2Link {
  font-family: var(--font-family-sans);
  font-style: normal;
  line-height: 23px;
  cursor: pointer;
  direction: rtl;
  display: inline-flex;
  align-items: center;

  &.small {
    font-size: var(--font-size-xs);
    svg {
      @include iconMedium();
    }
  }
  &.large {
    font-size: var(--font-size-s);
    svg {
      @include iconLarge();
    }
  }

  &.primary {
    color: rgb(var(--safetyOrange));
    text-decoration-line: none;
    font-weight: var(--font-weight-medium);
    svg {
      @include iconColor(rgb(var(--safetyOrange)));
    }
    &:hover {
      color: rgb(var(--spanishOrange));
      svg {
        @include iconColor(rgb(var(--spanishOrange)));
      }
    }
  }
  &.secondary {
    color: rgb(var(--jet));
    font-weight: var(--font-weight-normal);
    text-decoration-line: underline;
    svg {
      @include iconColor(rgb(var(--jet)));
    }
    &:hover {
      color: rgb(var(--safetyOrange));
      svg {
        @include iconColor(rgb(var(--safetyOrange)));
      }
    }
  }
  &.tertiary {
    color: rgb(var(--spanishGrey));
    text-decoration-line: none;
    font-weight: var(--font-weight-normal);
    svg {
      @include iconColor(rgb(var(--spanishGrey)));
    }
    &:hover {
      text-decoration-line: underline;
    }
  }
}
