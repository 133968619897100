
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.header {
  font-size: pxToRem(18);
  line-height: pxToRem(25);
  font-weight: 500;
  padding: 16px 12px;
  text-decoration: underline;
}
