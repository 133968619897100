$thumbMinHeight: 40px;
@mixin scrollbar($backgroundColor, $thumbColor) {
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: $backgroundColor;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border: 3px solid $backgroundColor;
    border-radius: 8px;
    min-height: $thumbMinHeight;
  }
  //for firefox
  @-moz-document url-prefix() {
    scrollbar-color: $thumbColor $backgroundColor;
    scrollbar-width: auto;
  }
}
