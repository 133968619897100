
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.subCategoryList {
  color: rgb(var(--pastelGrey));

  @include mobile {
    display: none;
    &.isCollapsed {
      display: block;
      margin-top: 10px;
      line-height: 30px;
    }
  }
}

.twoColumns {
  @include desktop {
    column-count: 2;
    column-gap: 20px;
  }
}
