
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';
@import '../scss-variables.scss';

.box {
  @include desktop {
    display: none;
  }

  position: relative;
  z-index: var(--header-index);
  height: $mobile-header-size;
  padding: 0 $header-mobile-padding;

  &.hasShadow {
    box-shadow: var(--shadow-level-2);
  }

  &.isSticky {
    position: fixed;
    width: 100%;
  }

  &.orange {
    background-color: rgb(var(--white));
    .icon {
      fill: rgb(var(--black));
    }
  }

  &.white {
    background-color: rgb(var(--white));
    .icon {
      fill: rgb(var(--black));
    }
  }

  .container {
    height: $mobile-header-size;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonsColumn {
      display: flex;
      min-height: 100%;
      min-width: 45px;
    }

    .middleFloat {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon {
      font-size: 25px;
    }

    .iconButtonWrapper {
      padding: 7px 10px;
    }
  }
}
