
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.logo {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
