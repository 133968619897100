/**
  * Note*
  * After adding a color add it in this file: libs/mango/src/styles/stories/colors-list.ts
  */
:root {
  --transparent: 'transparent';
  --white: 255, 255, 255;
  --snow: 248, 248, 248;
  --isabelline: 238, 238, 238;
  --whiteSmoke: 242, 242, 242;
  --gainsboro: 220, 220, 220;
  --pastelGrey: 204, 204, 204;
  --philippineSilver: 178, 178, 178;
  --spanishGrey: 153, 153, 153;
  --graniteGrey: 130, 130, 130;
  --jet: 54, 54, 54;
  --black: 0, 0, 0;

  // Primary
  --linen: 255, 241, 229;
  --peachPuff: 255, 223, 196;
  --bianca: 255, 250, 245;
  --safetyOrange: 255, 113, 0;
  --spanishOrange: 216, 96, 0;
  --vividOrange: 255, 127, 48;
  --yad2Gradient: linear-gradient(135deg, #f2cd48 0%, #fd803f 100%);
  --drushimSafetyOrange: 255, 113, 0, 1;

  // Grey
  --lightGrey: 235, 235, 235;

  // Green
  --azureishWhite: 223, 247, 237;
  --caribbeanGreen: 16, 173, 111;
  --greenCyan: 14, 156, 100;
  --emerald: 21, 206, 133;

  // Red
  --mistyRose: 255, 232, 232;
  --rouge: 255, 42, 43;
  --lave: 207, 26, 27;

  // Yad1
  --aliceBlue: 235, 247, 255;
  --azure: 4, 149, 255;
  --ocean: 0, 99, 170;
  --yad1Gradient: linear-gradient(225deg, #0cd9e5 0%, #0495ff 100%);

  // Ad colours
  --lemonChiffon: 253, 249, 197;
  --adMistyRose: var(--mistyRose);

  // Information index colors
  // Purple
  --paleLavender: 229, 209, 248;
  --darkOrchid: 139, 66, 208;
  --violet: 88, 0, 170;

  // Green
  --lightCyan: 223, 247, 244;
  --mountainMeadow: 34, 193, 161;

  // Blue
  --ghostWhite: 246, 249, 255;
  --glitter: 224, 236, 255;
  --bleuDeFrance: 53, 120, 229;
  --airForceBlue: 10, 61, 143;
  --royalBlue: 53, 120, 229;
  --blueShade: 10, 61, 143;
  --blueLight: 79, 140, 241;

  // Red
  --redCrayola: 226, 65, 77;
  --deepRed: 255, 42, 43, 1;

  // Light Orange
  --cosmicLatte: 255, 248, 229;
  --chromeYellow: 255, 171, 0;
  --darkOrange: 216, 96, 0;

  // others
  --accessibilityColor: 0, 47, 117;
  --border-color: var(--isabelline);
  --facebook: 66, 103, 178;
  --lightPink: 255, 243, 243, 1;

  // Themes
  --main-color: var(--safetyOrange);
}
// rgba(255, 243, 243, 1)
