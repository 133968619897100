
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.root {
  @include desktop {
    position: relative;
    height: 100%;
  }

  font-size: var(--font-size-s);

  svg {
    font-size: 1.5em;
  }
}

.list {
  display: flex;

  @include desktop {
    height: 100%;
    flex-direction: row;
  }

  @include mobile {
    margin-top: 10px;
    flex-direction: column;
  }
}
