
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.item {
  &:not(:last-child) {
    border-bottom: 1px solid rgb(var(--isabelline));
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 12px;

    &:hover {
      background-color: rgb(var(--isabelline));

      .text {
        font-weight: 500;
      }
    }

    .image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }

    .text {
      margin-right: 10px;
      flex-grow: 1;
      font-size: pxToRem(16);
      line-height: pxToRem(23);
      text-align: right;

      @include trim-table();

      .icon {
        line-height: 0;
      }

      * {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
