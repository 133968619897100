
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.aboutLinks {
  display: flex;
  color: rgb(var(--spanishGrey));

  @include desktop {
    justify-content: center;
    margin: 20px 0;

    .linkItem {
      margin: 0 15px;
    }
  }

  @include mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 15px;
    margin: 30px 0;

    .linkItem {
      width: 100px;
      font-size: var(--font-size-xs);
      padding: 3px 0;
      text-align: center;
    }
  }
}
