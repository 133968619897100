/* stylelint-disable custom/enforce-breakpoint-mixin */

/**
--- MEDIA BREAKPOINTS QUERIES ---

-- Usage:
    @include mobile {
      color: blue;
      ...
    }
    @include desktop {
      color: green;
      ...
    }
    Note* if you need something custom just use a regular css @media command
*/

$mobile: 880px;

@mixin mobile() {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: #{$mobile + 1}) {
    @content;
  }
}

@mixin smallDesktop() {
  @media screen and (min-width: $mobile) and (max-width: 1024px) {
    @content;
  }
}

@mixin wideDesktop() {
  @media screen and (min-width: 1366px) {
    @content;
  }
}

/** for backward compatibility only (please use the mixins above ^) **/
$break1: '(max-width:#{$mobile})';
$break2open: '(min-width:#{$mobile + 1})';
