
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.section {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  vertical-align: top;
  width: 100%;
  background-color: rgb(var(--white));

  .content {
    align-items: center;
    margin: auto;

    .title {
      margin: 40px 50px 0px 50px;

      @include desktop {
        font-size: var(--font-size-xxxxxl);
      }

      @include mobile {
        font-size: var(--font-size-xxl);
      }
    }

    .subTitle {
      margin: 20px 40px 0px 40px;
      font-size: var(--font-size-m);
    }

    .button {
      text-align: center;
      display: block;
      border-radius: 5px;
      padding: 12px 24px;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-s);
      background-color: rgb(var(--safetyOrange));
      color: rgb(var(--white));
      border-radius: 30px;
      margin: 40px auto 0px auto;
    }

    .info {
      background: rgb(var(--isabelline));
      border-radius: 6px;
      color: rgb(var(--graniteGrey));
      line-height: 20px;
      margin: 40px 27px 0px 27px;

      @include desktop {
        padding: 15px 22px;
      }

      @include mobile {
        padding: 15px 11px;
      }
    }
  }

  .statusCode {
    color: #999999;
    font-size: var(--font-size-s);
    margin-top: 20px;
  }
}
