
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.strip {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(var(--jet));
  border-radius: 8px;
  padding: 10px;
  color: white;
  font-size: var(--font-size-s);
  width: 750px;
  max-width: calc(100% - 20px);
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: calc(var(--popups-index) + 50);
  border: 2px solid rgb(var(--pastelGrey));

  &.closed {
    display: none;
  }

  .right {
    margin-left: auto;
  }
  .left {
    margin-right: auto;
  }
  .detailsButton {
    vertical-align: middle;
    color: rgb(var(--safetyOrange));
    font-weight: var(--font-weight-medium);
    margin-left: 15px;
  }
  .noticeIcon {
    @include iconLarge(white);
    margin-left: 5px;
    vertical-align: middle;
  }
  .notice {
    vertical-align: middle;
  }
  .closeButton {
    line-height: 0;
    vertical-align: middle;
    // for accessibility button must be atleast 40x04
    padding: 8px;
    margin: -8px;

    .closeIcon {
      @include iconLarge(white);
    }
  }
}
