
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.button {
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: pointer;
  // accessibility pressing zone 40x40
  padding: 9px;
  margin: -9px;
  z-index: 1;

  .icon {
    vertical-align: middle;
    @include iconLarge();
  }
}
