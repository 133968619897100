
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.baseIndex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 960px;
  max-width: 100%;
  margin: auto;

  h1 {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-xl);
    margin-top: 10px;
  }
}

.grey {
  background-color: #f9f9f9;
}
