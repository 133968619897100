
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '../../base/variants-variables.scss';
@import './icon-sizes-mixins.scss';
@import './icon-colors-mixins.scss';

.spinner {
  margin-left: 6px;

  @each $value in $all-variants {
    &.default-loader-color-#{$value} {
      @include fetchLoaderVariantStyle($value);
    }
    &.desktop-loader-color-#{$value} {
      @include desktop {
        @include fetchLoaderVariantStyle($value);
      }
    }
    &.mobile-loader-color-#{$value} {
      @include mobile {
        @include fetchLoaderVariantStyle($value);
      }
    }
  }

  &.alwaysOrange {
    svg {
      @include iconColor(rgb(var(--safetyOrange)), rgb(var(--safetyOrange)));
    }
  }

  @each $size in $all-sizes {
    &.default-loader-#{$size} {
      @include fetchWithLoaderSize($size);
    }
    &.desktop-loader-#{$size} {
      @include desktop {
        @include fetchWithLoaderSize($size);
      }
    }
    &.mobile-loader-#{$size} {
      @include mobile {
        @include fetchWithLoaderSize($size);
      }
    }
  }
}
