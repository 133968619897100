
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 12px;

  &.border {
    border-bottom: 1px solid rgb(var(--isabelline));
  }

  .image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-left: 10px;
  }

  .text {
    flex-grow: 1;
    font-size: pxToRem(16);
    line-height: pxToRem(18);
    color: rgb(var(--jet));
    text-align: right;

    @include trim-table();
  }

  .icon {
    @include iconSmall();
    line-height: 0;
  }
}
