/* check if variable exists for storybook builds */
$assetsPath: if(
  global-variable-exists('y2AssetsPath'),
  $y2AssetsPath,
  './for-localhost'
);

$path: if(
  not(global-variable-exists('envName')) or $envName == 'local',
  './for-localhost',
  '#{$assetsPath}mango/fonts'
);

/* rubik-300 - hebrew_cyrillic_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Rubik Light'),
    local('Rubik-Light'),
    url('#{$path}/rubik-v7-hebrew_cyrillic_latin-300.woff2') format('woff2'); /* Super Modern Browsers */
}
/* rubik-regular - hebrew_cyrillic_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Rubik'),
    local('Rubik-Regular'),
    url('#{$path}/rubik-v7-hebrew_cyrillic_latin-regular.woff2') format('woff2'); /* Super Modern Browsers */
}
/* rubik-500 - hebrew_cyrillic_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Rubik Medium'),
    local('Rubik-Medium'),
    url('#{$path}/rubik-v7-hebrew_cyrillic_latin-500.woff2') format('woff2'); /* Super Modern Browsers */
}
/* rubik-700 - hebrew_cyrillic_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Rubik Bold'),
    local('Rubik-Bold'),
    url('#{$path}/rubik-v7-hebrew_cyrillic_latin-700.woff2') format('woff2'); /* Super Modern Browsers */
}

body {
  font-family: 'Rubik', sans-serif;
}
