
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/styles';
@import '@y2/mango/fonts';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .footer {
    margin-top: auto;
  }
}
