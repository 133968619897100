
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.list {
  text-align: right;

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  .header {
    display: block;
    font-weight: 500;
    font-size: pxToRem(16);
    line-height: pxToRem(23);
  }

  .item {
    display: block;
    font-size: pxToRem(14);
    line-height: pxToRem(20);
  }
}
