@import '../base/variants-variables.scss';

@mixin primaryIconDisable {
  &:disabled {
    .icon {
      svg {
        @include iconColor(rgb(var(--pastelGrey)));
      }
    }
  }
}

@mixin secondaryIconDisable {
  &:disabled {
    .icon {
      svg {
        @include iconColor(rgb(var(--pastelGrey)));
      }
    }
  }
}

@mixin primaryIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--white)));
    }
  }
  @include primaryIconDisable();
}

@mixin blueIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--white)));
    }
  }
  @include primaryIconDisable();
}

@mixin secondaryIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--safetyOrange)));
    }
  }
  @include secondaryIconDisable();
}

@mixin tertiaryIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--jet)));
    }
  }
  @include secondaryIconDisable();
}

@mixin greenIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--white)));
    }
  }
  @include primaryIconDisable();
}

@mixin greenOutlinedIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--caribbeanGreen)));
    }
  }
  @include primaryIconDisable();
}

@mixin blueOutlinedIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--azure)));
    }
  }
  @include primaryIconDisable();
}

@mixin transparentIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--jet)));
    }
  }
  @include secondaryIconDisable();
}

@mixin transparentPrimaryIconStyle {
  .icon {
    svg {
      @include iconColor(rgb(var(--safetyOrange)));
    }
  }
  @include secondaryIconDisable();
}

@mixin fetchIconVariantStyle($value) {
  @if $value == $primary {
    @include primaryIconStyle();
  } @else if $value == $secondary {
    @include secondaryIconStyle();
  } @else if $value == $tertiary {
    @include tertiaryIconStyle();
  } @else if $value == $green {
    @include greenIconStyle();
  } @else if $value == $transparent {
    @include transparentIconStyle();
  } @else if $value == $transparentPrimary {
    @include transparentPrimaryIconStyle();
  } @else if $value == $greenOutlined {
    @include greenOutlinedIconStyle();
  } @else if $value == $blue {
    @include blueIconStyle();
  }
}
