
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.categoryItem {
  @include desktop {
    .categoryNameContainer {
      pointer-events: none;
      .arrowIcon {
        display: none;
      }
    }
  }
  @include mobile {
    padding: 15px 0;
    border-top: 1px solid #5a5a5a;
    &:last-of-type {
      border-bottom: 1px solid #5a5a5a;
    }

    .categoryNameContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowIcon {
        margin-right: auto;
        fill: rgb(var(--white));
        transform: rotate(90deg);
        transition: 0.3s;
      }
    }
    &.isCollapsed {
      .arrowIcon {
        transform: unset;
      }
    }
  }
}

.categoryItemName {
  color: rgb(var(--white));
  font-size: var(--font-size-m);

  @include desktop {
    margin-bottom: 10px;
  }

  @include mobile {
    pointer-events: none;
    margin-bottom: 0;
    color: rgb(var(--white));
    font-size: var(--font-size-m);
  }
}
