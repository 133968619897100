@import './variants-variables.scss';

@mixin smallSize() {
  min-height: 36px;
  font-size: var(--font-size-xs);
  padding: 0 18px;
}

@mixin mediumSize() {
  min-height: 42px;
  padding: 0 20px;
  font-size: var(--font-size-s);
}

@mixin largeSize() {
  min-height: 48px;
  padding: 0 24px;
  font-size: var(--font-size-m);
}

@mixin fetchSizeStyle($value) {
  @if $value == $small {
    @include smallSize();
  } @else if $value == $medium {
    @include mediumSize();
  } @else if $value == $large {
    @include largeSize();
  }
}
