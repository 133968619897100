
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';

.socialButtonsContainer {
  display: flex;
  justify-content: center;

  .iconItem:not(:first-child) {
    margin-right: 20px;
  }

  .iconButton {
    white-space: nowrap;
    width: 35px;
    height: 35px;
    background-color: rgb(var(--black));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    @include iconCustom(18px, rgb(var(--white)));
  }
}
