/** DEPRECATED simple use the global pxToRem method, font-size: pxToRem(8);
see: libs/mango/src/mixins/converters.scss
*/
:root {
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;

  --font-size-xxxxxxl: 2.74rem;
  --font-size-xxxxxl: 2.25rem;
  --font-size-xxxxl: 2rem;
  --font-size-xxxl: 1.875rem;
  --font-size-xxl: 1.75rem;
  --font-size-xl: 1.5rem;
  --font-size-l: 1.25rem;
  --font-size-m: 1.125rem;
  --font-size-s: 1rem;
  --font-size-xs: 0.875rem;
  --font-size-xxs: 0.75rem;
  --font-size-xxxs: 0.625rem;
}
